.question {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);

    animation-name: fadeInFromNone;
    animation-duration: 1s;

    p {
        color: black;
        font-size: 50px;
        text-align: center;
        font-family: "JurassicPark";

        padding-left: 20px;
        padding-right: 20px;

        position: absolute;
    }
}