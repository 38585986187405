body {
    background-image: url(../img/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

    padding: 0;
    margin: 0;

    &.blur {
        backdrop-filter: blur(15px);

        animation-name: fadeInFromNone;
        animation-duration: 1s;
    }
}