.wrong {
    color: red !important;
}

.right {
    color: #2b8618 !important;
}

.normalFont {
    font-family: sans-serif;
    font-size: 30px;

    a {
        text-decoration: underline;
        color: unset;
    }
}