@import "font";

@media screen and (orientation: landscape) {
  @import "body";
  @import "buttonJumanji";
  @import "question";
  @import "keyframes";
  @import "taskText";
  @import "questionInput";
  @import "answer";
}

@media screen and (orientation: portrait) {
    .buttonJumanji {
        display: none;
    }
}
